import React from 'react';
import Layout from '../components/layout';
import Button from '../components/layout/button';

const NotFoundPage = () => (
  <Layout
    seo={{
      title: 'Gatsby Starter',
      lang: 'en',
      description: 'Valami nincs rendben, próbád meg újra.'
    }}
  >
    <div className="mt-40 mb-20 text-center">
      <h1 className="text-6xl m-10">404</h1>
      <h2 className="mx-auto">Valami nincs rendben, próbáld meg újra.</h2>
      <Button className="mt-8 primary" to="/">
        Vissz a Főoldalra
      </Button>
    </div>
  </Layout>
);

export default NotFoundPage;
